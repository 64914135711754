import axios from "axios";

const base_url = "https://api.gamerclub.live";

// signup form
export let signupForm = async (formData) => {
  let { data } = await axios.post(`${base_url}/api/register`, formData);
  return data;
};

// get signin api
export let signinForm = async (formData) => {
  let { data } = await axios.post(`${base_url}/api/login`, formData);
  return data;
};

export let signinFormEts = async (formData) => {
  let { data } = await axios.post(
    `https://vas1.grand-hub.com/api/gamerClub/login`,
    formData
  );
  return data;
};

export let saveTracker = async (data) => {
  const url = "http://api.gamerclub.live/api/tracker/store";
  const res = await axios({
    method: "post",
    url: url,
    data: data,
  });

  return res.data;
};

export let setAgencyData = async (data) => {
  if (data) {
    if (data.trafficsource) {
      localStorage.setItem("tracker", data.parameter);
      localStorage.setItem("trackerCompany", data.trafficsource);
      //console.log("finish tracker")
      // logtracker(data.trafficsource,data.parameter);
    } else if (data.ID) {
      localStorage.setItem("ID", data.ID);
      //console.log("finish tracker" , data.ID)
    }
  }
};

// send otp code from api
export let linkOtpCode = async (formData) => {
  let { data } = await axios.post(`${base_url}/api/verify-otp`, formData);
  return data;
};

// get signup api
export let userLogout = async (token) => {
  let { data } = await axios.post(`${base_url}/api/logout`, null, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return data;
};

export let unsubscribe = async (token) => {
  let { data } = await axios.post(`${base_url}/api/unsubscribe`, null, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return data;
};

// get category details
export let getUserCoins = async (token) => {
  let { data } = await axios.get(`${base_url}/api/wallet`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  console.log(data);
  return data;
};

// home page games {android && html} api
export let getHomeGamesApi = async () => {
  let { data } = await axios.get(`${base_url}/api/games`);
  return data;
};

// get games categories
export let getGamesCategoriesApi = async (gamesType) => {
  let { data } = await axios.get(`${base_url}/api/games/${gamesType}`);
  return data;
};

// get games list
export let getGamesListApi = async (gamesType, cat_name, search) => {
  let { data } = await axios.get(`${base_url}/api/games/${gamesType}`, {
    params: {
      cat_name,
      search,
    },
  });
  return data;
};

// get games categories
export let getHomeVouchers = async (lang) => {
  let { data } = await axios.get(`${base_url}/api/vouchers/home`, {
    params: {
      rand: 10,
      lang,
    },
  });
  return data;
};

// get games categories
export let getHomeTournamnets = async () => {
  let { data } = await axios.get(`${base_url}/api/contests/home`);
  return data;
};

// home page subscription api
export let getHomeSubscription = async (langType) => {
  let { data } = await axios.get(`${base_url}/api/packages/home`, {
    params: {
      language: langType,
    },
  });
  return data;
};

// get game details
export let getGameDetails = async (gameId) => {
  let { data } = await axios.get(`${base_url}/api/games/${gameId}`);
  return data;
};

// home page subscription api
export let getStoreSubscription = async (type, langType) => {
  let { data } = await axios.get(`${base_url}/api/packages`, {
    params: {
      type,
      language: langType,
    },
  });
  return data;
};

// add to cart function
export let postCartItem = async (formData, sectionType, token) => {
  let { data } = await axios.post(
    `${base_url}/api/${sectionType}/store`,
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// add an item to cart
export let postWishListItem = async (formData, token) => {
  let { data } = await axios.post(`${base_url}/api/wishlist/store`, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// delete user cart & wishlist item
export let deleteUserWishlistItem = async (voucherId, token) => {
  let { data } = await axios.delete(
    `${base_url}/api/wishlist/delete/${voucherId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// add to cart
export let getUserCart = async (token) => {
  let { data } = await axios.get(`${base_url}/api/cart/show`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// add to cart
export let getUserWishlist = async (token) => {
  let { data } = await axios.get(`${base_url}/api/wishlist/show`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// delete user cart items
export let deleteUserCartItems = async (token) => {
  let { data } = await axios.delete(`${base_url}/api/cart/delete-all`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// delete user cart & wishlist item
export let deleteUserCartItem = async (deleteType, voucherId, token) => {
  let { data } = await axios.delete(
    `${base_url}/api/cart/${deleteType}/${voucherId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// update an item in cart
export let updateCartItem = async (formData, token) => {
  let { data } = await axios.patch(`${base_url}/api/cart/update`, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// get user profile data
export let getUserDetails = async (token) => {
  let { data } = await axios.get(`${base_url}/api/profile`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// update an item in cart
export let updateUserData = async (formData, token) => {
  let { data } = await axios.post(`${base_url}/api/profile`, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

// get categories
export let getCategories = async (querySearch, id, token, page, langType) => {
  let url = `http://api.gamerclub.live/api/voucher-details/pay-or-play?page=${page}&search=${querySearch}&lang=${langType}`;

  if (id !== "all" && id !== undefined) {
    url = url + `&menu=${id}`;
  }
  let { data } = await axios.get(url, [
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    },
  ]);
  return data;
};

// get all tournaments
export let getTournaments = async (token) => {
  let { data } = await axios.get(`${base_url}/api/contests`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// get tournament details
export let getTournamentDetails = async (tournamentId, token) => {
  let { data } = await axios.get(`${base_url}/api/contests/${tournamentId}`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// get tournament details
export let getTournamentLeaderBoards = async (tournamentId, token) => {
  let { data } = await axios.get(
    `${base_url}/api/contests/${tournamentId}/leaderboard`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// check tournament join
export let checkTournamentJoin = async (tournId, token) => {
  let { data } = await axios.post(
    `${base_url}/api/contests/${tournId}/join`,
    null,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// check tournament join
export let leaveTournament = async (tournId, token) => {
  let { data } = await axios.post(
    `${base_url}/api/contests/${tournId}/leave`,
    null,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get user vouchers items
export let getUserVouchers = async (token) => {
  let { data } = await axios.get(`${base_url}/api/orders`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// get profile game history
export let gameHistory = async (token) => {
  let { data } = await axios.get(`${base_url}/api/game-results`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
