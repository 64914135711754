import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OtpCode.css";
import "./etisalatSignup.css";
import OTPInput from "react-otp-input";
import otp_side from "../../assets/forms/side_otp_img.png";
import side_img from "../../assets/forms/side_signup_img.png";
import { Link, useNavigate } from "react-router-dom";
import { saveTracker, setAgencyData } from "../../Utilies/gamerClubApi";
import { Col, Row } from "react-bootstrap";
import FormsAside from "../../Components/FormsAside/FormsAside";
import { notify } from "../../Components/ToastComponent";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import AuthLanguageSelector from "./LanguageChange";
import logo from "../../assets/logo2.png";
import { Trans } from "react-i18next";

const EtisalatSignUp = () => {
  localStorage.setItem("i18nextLng", "ar");
  const { t } = useTranslation();
  const navigate = useNavigate();
  // lodaing state
  const [lodaing, setLodaing] = useState(true);
  // errors messages state
  const [errorMsg, setErrorMsg] = useState("");
  const [otp, setOtp] = useState("");
  const [transactionidentify, setTransactionidentify] = useState("");
  const [transactionid, setTransactionid] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [showSub, setshowSub] = useState(false);
  const [showPendding, setshowPendding] = useState(true);
  const [phonenumber, setphonenumber] = useState("");
  const [sub_id, setsub_id] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [errorresverify, seterrorresverify] = useState("");
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);
  const [showverif, setShowverif] = useState(false);
  const [id, setId] = useState(null);

  const [agree, setagree] = useState(false);

  // user state
  const [formData, setFormData] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  useEffect(() => {
    localStorage.setItem("i18nextLng", "ar");

    //yousry lang
    //console.log("yousry lang");
    // MCPShield();
    const params = queryString.parse(window.location.search);
    if (params.lang) {
      //console.log("lang",params.lang);
      localStorage.setItem("i18nextLng", params.lang);
      if (localStorage.getItem("i18nextLng") != params.lang) {
        localStorage.setItem("i18nextLng", params.lang);
        window.location.reload();
      }
      //window.location.reload();
      //localLang.checklocalLanguage();
    }
    // setAgencyData(params);
    if (params.camp) {
      setagree(true);
    }
    if (params.msisdn == undefined) {
      setshowSub(false);

      //  getRedirectURL(params);
      console.log(params);
    } else {
      var phone = params.msisdn;
      if (phone !== null && phone !== "") {
        //    fetchDataWithMsidn(phone);
      } else {
        setshowPendding(false);
      }
    }
  }, []);

  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem("tracker")) {
      var op_id = "zFGLFvkj8L";
      datag = {
        sendCamp: 1,
        company: localStorage.getItem("trackerCompany"),
        key: localStorage.getItem("tracker"),
        operator_id: 3,
        msisdn: GMSISDN,
      };
      //console.log(datag)
    }
    await saveTracker(datag);
  };

  function manipulateMsisdn(msisdn) {
    msisdn = msisdn.replace("+", "");
    if (startsWith(msisdn, "0")) {
      msisdn = msisdn.substring(1);
    } else if (startsWith(msisdn, "00")) {
      msisdn = msisdn.substring(2);
    }
    if (!startsWith(msisdn, "213")) {
      msisdn = "213" + msisdn;
    }
    return msisdn.trim();
  }

  function startsWith(str, prefix) {
    return str.indexOf(prefix) === 0;
  }

  // get form inputs values
  function getFormData({ target }) {
    //console.log(target.value.replace(/[^0-9]/g, ""));
    setActiveData(target.value.replace(/[^0-9]/g, ""));
    //    setphonenumber(target.value.replace(/[^0-9]/g, ""));
    setshowSub(false);
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  }

  const Subscribe = async (e) => {
    //console.log(e.target.phone_number.value);
    setActiveData(e.target.phone.value);
    const phone_number_val = e.target.phone.value;
    //console.log(activeData);
    e.preventDefault();

    if (phone_number_val) {
      var phon_n = phone_number_val.toString();
      console.log(manipulateMsisdn(phon_n));
      const url = `https://vas1.grand-hub.com/api/gamerClub/etisalat-uae-d/subscribe`;
      setLodaing(false);
      await axios
        .post(url, { phone: phon_n })
        .then((res) => {
          console.log(res);
          if (res.data.success == true) {
            setId(res.data?.subscriptionId);
            setShowverif(true);
          } else {
            if (res.data.msg != null) {
              setErrorMsg(res.data.msg);
              setLodaing(false);
            } else {
              setLodaing(false);

              setErrorMsg(
                t(
                  "Something went wrong! Please make sure you have the correct number inserted or try again later.-ar"
                )
              );
            }
            // setShowverif(true)
          }
          setLodaing(true);
        })
        .catch(function (error) {
          setErrorMesg(
            setErrorMsg(
              t(
                "Something went wrong! Please make sure you have the correct number inserted or try again later.-ar"
              )
            )
          );
          setShowverif(false);
          setLodaing(false);
        })
        .finally(function () {
          //console.log('complete');
        });
    }
  };

  const verify = async (e) => {
    e.preventDefault();
    if (otp) {
      var phon_n = activeData;
      setLodaing(false);

      axios
        .post(
          `https://vas1.grand-hub.com/api/gamerClub/etisalat-uae-d/verify`,
          {
            phone: phon_n,
            otp: otp,
            subscriptionId: id,
          }
        )

        .then((res) => {
          //console.log(res);
          setLodaing(true);
          if (res.data.success == true) {
            // tracker(phon_n);
            // gtag_report_conversion();
            notify("OTP Code Is Correct");
            setTimeout(() => {
              navigate("/success", { replace: true });
            }, 2500);
          } else {
            setLodaing(false);

            // tracker(phon_n);
            if (res.data.msg != null) {
              setErrorMsg(res.data.msg);
              //setVerfiErrorMesg(true);
            } else {
              setErrorMsg(
                t(
                  "Something went wrong! Please make sure you have the correct verification code inserted or try again later.-ar"
                )
              );
              //----setVerfiErrorMesg(true);
            }
            // setVerfiErrorMesg(true);
            // seterrorresverify(res.data.msg);
          }
          setLodaing(true);
        })
        .catch(function (error) {
          //console.log(error);
          //    setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);
        })
        .finally(function () {
          //console.log('complete');
        });
    }
  };

  // send data to api
  function sendData(e) {
    e.preventDefault();
    if (showSub) {
      // subscribeHe(e);
    } else {
      Subscribe(e);
    }
    // e.preventDefault();
    // subscribeHe(e);
    // try {
    //   Subscribe(e);
    //   // setLodaing(false);
    //   // if(showSub){
    //   //   subscribeHe();
    //   // }
    //   // else{
    //   //   //Subscribe()
    //   //   setShowverif(true);
    //   // }

    //   /*
    //   await signupForm(formData);
    //   setErrorMsg("");
    //   localStorage.setItem("userPhone", formData.phone);
    //   notify("SignUp Successfully");
    //   */

    // } catch (error) {
    //   console.log(error);
    //   setErrorMsg(error.response?.data?.error);
    //   setLodaing(true);
    // }
  }
  const verifyOtp = async () => {
    try {
      setLodaing(false);
      // let formData = {
      //   phone: phone_number,
      //   otp: otp,
      // };
      // let result = await linkOtpCode(formData);
      // console.log(result);
      // // success alert message
      notify("OTP Code Is Correct");
      // localStorage.setItem("user_id", result?.user?.id);
      // // store token to local storage
      // localStorage.setItem("token", result?.access_token);
      // localStorage.setItem("userImage", JSON.stringify(result?.user?.avatar));
      notify("OTP Code Is Correct");
      setTimeout(() => {
        navigate("/success", { replace: true });
      }, 2500);
    } catch (error) {
      if (error) {
        //notify1(error?.response?.data?.message);
      }
      setLodaing(false);
      setOtp("");
    }
  };
  function gtag_report_conversion() {
    let tracker_value = localStorage.getItem("tracker");
    if (localStorage.getItem(tracker_value) != "1") {
      window.gtag("event", "conversion", {
        send_to: "AW-619421651/C_ScCIHe_qcZENO_rqcC",
      });
      localStorage.setItem(tracker_value, "1");
    }
    return false;
  }

  return (
    // <div className="signUp overflow-x-hidden">
    //   <ToastContainer />
    //   <Row
    //     className="mx-0"
    //     dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
    //   >
    //     <div className="col-lg-4 p-0 overflow-hidden">
    //       <FormsAside side_img={side_img} />
    //     </div>
    //     <div className="col-lg-8 mt-4 mt-md-0">
    //       <div className="form">
    //         <Row className="h-20 page-content">
    //           <Col className="m-auto" xs={12} md={8} lg={8}>
    //             {/* <h1>{t("create account-ar")}</h1> */}

    //             {/* <div className="google"> */}
    //             {/* <img src={google_img} alt="google" className="mx-2" />
    //             {t("sign up with Google")} */}
    //             {/* </div> */}
    //             {/* <span>{t("-OR-")}</span> */}
    //             {errorMsg ? (
    //               <div className="text-left alert alert-danger">
    //                 <p>{errorMsg}</p>
    //               </div>
    //             ) : (
    //               ""
    //             )}

    //             {!showverif ? (
    //               <form className="" onSubmit={sendData}>
    //                 {" "}
    //                 <div className="ms-auto lang">
    //                   <AuthLanguageSelector />
    //                 </div>
    //                 <p>{t("24HRFree")}</p>
    //                 {/* <p>
    //                   {t("Free for 24 hours then AED 2 per day (excl. VAT)")}
    //                 </p> */}
    //                 <p className="larger">
    //                   {t("Join an online platform for gamers")}
    //                 </p>
    //                 <p>
    //                   {t(
    //                     "compete in tournaments and track their performance through leaderboards. It offers a space for both casual and competitive players across various games."
    //                   )}
    //                 </p>
    //                 <h3>
    //                   {t("Enter your Etisalat Mobile Number to receive OTP")}
    //                 </h3>
    //                 {/* <div className="gap-5 d-flex justify-content-between align-items-center">
    //               <input
    //                 onChange={getFormData}
    //                 name="first_name"
    //                 type="text"
    //                 placeholder={t("first_name")}
    //                 required
    //               />
    //               <input
    //                 onChange={getFormData}
    //                 name="last_name"
    //                 type="text"
    //                 placeholder={t("last_name")}
    //                 required
    //               />
    //             </div> */}
    //                 {/* <input
    //               onChange={getFormData}
    //               name="email"
    //               type="email"
    //               placeholder={t("email")}
    //               required
    //             /> */}
    //                 <div className="row m-0">
    //                   <div className="col-9">
    //                     <input
    //                       dir={
    //                         localStorage.getItem("i18nextLng") === "ar"
    //                           ? "rtl"
    //                           : "ltr"
    //                       }
    //                       onChange={getFormData}
    //                       value={activeData}
    //                       name="phone"
    //                       className="phone form-control"
    //                       type="tel"
    //                       placeholder={t("Phone-Ar")}
    //                       required
    //                     />
    //                   </div>
    //                   <div className="col-3">
    //                     <select className="form-control" dir="ltr">
    //                       <option value="+971">+971 </option>
    //                     </select>
    //                   </div>
    //                 </div>
    //                 <div className="submit-info">
    //                   <button
    //                     //   disabled={agree == true ? "" : "disabled"}
    //                     type="submit"
    //                     id="signupbut"
    //                     className="btn-submit"
    //                   >
    //                     {lodaing ? (
    //                       t("Create Account-Ar")
    //                     ) : (
    //                       <i className="fas fa-spinner fa-spin"></i>
    //                     )}
    //                   </button>
    //                   <h4> {t("24HRFreeAfterClicikinSubscribe")}</h4>
    //                   <p>
    //                     {t(
    //                       "After clicking Subscribe you will receive PIN message to confirm your subscription"
    //                     )}

    //                   </p>
    //                   <div className="ms-auto">
    //                     <button
    //                       className="exit"
    //                       onClick={() => {
    //                         window.location.href = `https://www.google.com/`;
    //                       }}
    //                     >
    //                       {t("exit ")}
    //                     </button>{" "}
    //                   </div>
    //                   <div className="d-flex align-items-center justify-content-center ">
    //                     {/* <h6 className="form-check-label mb-0 fw-bold">قبول الشروط والأحكام</h6>
    //                   <input
    //                     type="checkbox"
    //                     className="form-check-input mt-0 me-2 check-box "

    //                     onChange={(e) => {
    //                       setagree(!agree);
    //                     }}
    //                     checked={agree}
    //                   /> */}
    //                   </div>
    //                   {/*
    //                 <div>
    //                   <p className="text-center fw-medium mb-0 mt-4">
    //                   ملحوظة: سوف تكون مشتركًا في Ludo برقمك
    //                   الهاتف الحالي
    //                   </p>
    //                   <p className="text-center fw-medium mb-0">
    //                   رسوم الاشتراك هي 35.00 دج يوميا
    //                   </p>
    //                   <p className="text-center fw-medium mb-0">
    //                   لمزيد من المعلومات، يرجى الاتصال بنا على
    //                   support@grandmstg.com{" "}
    //                   </p>

    //                 </div> */}
    //                   <div className="terms overflow-x-hidden">
    //                     <h6>{t("TERMS AND CONDITIONS")}</h6>
    //                     <p>{t("By Clicking on Subscribe,condistion-ku")}</p>
    //                     <ul
    //                       style={{
    //                         fontSize: "13px",
    //                         textAlign: "start",
    //                         padding: "10px",
    //                         listStyle: 'inside',
    //                         direction:t('lg')

    //                       }}
    //                     >
    //                       <li>{t("No commitment")}</li>

    //                       <li>{t("A standard subscription")}</li>
    //                       <li>{t("A FREE TRIAL period")}</li>
    //                       <li>{t("renewed")}</li>
    //                       <li>{t("ageCondition")}</li>
    //                       <li>{t("support")}</li>
    //                       <li>{t("furtherTerms")}</li>

    //                       <li>{t("ByProceeding")}</li>
    //                     </ul>
    //                   </div>

    //                   {/* <h3>
    //                   {t("Already Have An Account?-ar")}{" "}
    //                   <Link to="/signin">{t("signin-ar")}</Link>
    //                 </h3> */}
    //                 </div>
    //                 <div className="d-flex justify-content-around">
    //                   <div className="ml-3 ms-5">
    //                     <a href="terms" target="_blank">
    //                       {t("Terms And Conditions")}
    //                     </a>{" "}
    //                   </div>
    //                   {/* {t("To view the")}{" "} */}
    //                   <div className="me-5">
    //                     <a href="privacy" target="_blank">
    //                       {t("privacy policy")}
    //                     </a>{" "}
    //                   </div>{" "}
    //                   {/* {t("To view the")}{" "} */}
    //                 </div>
    //               </form>
    //             ) : null}

    //             {showverif ? (
    //               <OTPInput
    //                 inputType="number"
    //                 containerStyle={{
    //                   justifyContent: "center",
    //                   direction: "ltr",
    //                 }}
    //                 inputStyle={{
    //                   width: "40px",
    //                   height: "50px",
    //                   justifyContent: "space-between",
    //                   border: "2px solid black",
    //                   direction: "ltr",
    //                 }}
    //                 value={otp}
    //                 onChange={setOtp}
    //                 numInputs={6}
    //                 renderSeparator={<span>-</span>}
    //                 renderInput={(props) => <input required {...props} />}
    //                 shouldAutoFocus={true}
    //               />
    //             ) : null}

    //             {showverif ? (
    //               <div className="submit-info">
    //                 <button
    //                   onClick={(e) => verify(e)}
    //                   className="btn-submit w-100"
    //                 >
    //                   {lodaing ? (
    //                     t("Verify Otp") //اشترك
    //                   ) : (
    //                     <i className="fas fa-spinner fa-spin"></i>
    //                   )}
    //                 </button>
    //               </div>
    //             ) : null}
    //           </Col>
    //         </Row>
    //       </div>
    //     </div>
    //   </Row>
    // </div>
    <div className=" py-md-3   signup-section" dir={t("lg")}>
      <div className="col-md-6 m-auto  bg-white py-2 px-2 rounded-md">
        <div className="col-2">
          <AuthLanguageSelector className="d-inline" />
        </div>
        <div className="signup-content col-12">
          <p
            className="des text-center "
            style={{ fontWeight: "bold", fontSize: "12px" }}
          >
            {t("24HRFree")}
          </p>
          <div className="logo d-flex justify-content-center">
            <img src={logo} alt="" width="100px" />
          </div>
          <div className="service-description text-center mt-2">
            <h6
              className="font-weight-bold"
              style={{ fontWeight: "bold", color: "#274052" }}
            >
              {t("Join an online platform for gamers")}
            </h6>
            <p style={{ fontSize: "12px" }}>
              {t(
                "compete in tournaments and track their performance through leaderboards. It offers a space for both casual and competitive players across various games."
              )}
            </p>
          </div>
          {!showverif ? (
            <div className="form-section overflow-hidden ">
              <h5
                className="py-2 px-2 mb-0 text-center"
                style={{
                  background: "#bed3ce",
                  fontSize: "13px",
                  fontWeight: "bolder",
                }}
              >
                {t("Enter your Etisalat Mobile Number to receive OTP")}
              </h5>
              <div>
                <form
                  dir="ltr"
                  onSubmit={sendData}
                  action=""
                  className=" w-100 mt-0 m-auto py-2 px-2"
                  style={{ background: "rgb(206 215 215 / 30%)" }}
                >
                  <div className="d-flex justify-content-around">
                    <div className="form-group col-auto mr-2 px-2 ps-0">
                      <select
                        style={{ height: "33px" }}
                        className="form-select form-control shadow-none"
                        dir="ltr"
                      >
                        <option value="+971" selected>
                          +971
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-8">
                      <input
                        style={{ height: "30px" }}
                        className="form-control phone shadow-none"
                        id=""
                        aria-describedby="helpId"
                        onChange={getFormData}
                        value={activeData}
                        name="phone"
                        type="tel"
                        placeholder={t("Phone-Ar")}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <button type="submit" id="signupbut" className="btn-submit">
                      {lodaing ? (
                        t("subscribe")
                      ) : (
                        <i className="fas fa-spinner fa-spin"></i>
                      )}
                    </button>
                  </div>
                  <div className="message mt-2 d-flex flex-column">
                    {/* <span className="success-message bg-success">Success</span> */}
                    {errorMsg ? (
                      <small className="error-msg text-danger text-center py-1 rounded-sm">
                        <small className="error-msg text-danger text-center py-1 rounded-sm">
                          {errorMsg}
                        </small>
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
                <div>
                  <div className="exit text-center">
                    <a className="mb-2" href="https://www.google.com/">
                      {t("exit")}
                    </a>
                  </div>
                  <hr className="my-1 " />
                  <p
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                    className="des text-center mb-1"
                  >
                    {t("24HRFree")}
                  </p>
                  <hr className="mt-0 mb-2" />

                  <h6 className="font-bold mt-2 text-center">
                    {t(
                      "After clicking Subscribe you will receive PIN message to confirm your subscription"
                    )}
                  </h6>

                  <div
                    className="terms-section p-5"
                    style={{ direction: t("lg") }}
                    dir={t("lg")}
                  >
                    <h6 style={{ direction: t("lg"), fontWeight: "bold" }}>
                      {t("TERMS AND CONDITIONS")}
                    </h6>
                    <small>{t("By Clicking on Subscribe,condistion-ku")}</small>
                    <ul
                      dir={t("lg")}
                      className=" mx-1 py-2"
                      style={{
                        listStyle: "disc !important",
                        fontSize: "12px",
                        direction: t("lg"),
                        listStyleType: "disc",
                      }}
                    >
                      <li> {t(" Enjoy your FREE TRIAL For 24 hours")}</li>
                      <li> {t("No commitment")}</li>
                      <li> {t("A standard subscription")}</li>
                      <li> {t("A FREE TRIAL period")}</li>
                      <li> {t("renewed")}</li>
                      <li> {t("ageCondition")}</li>
                      <li> {t("support")}</li>
                      <li> {t("getSupport")}</li>

                      <li>
                        {" "}
                        <Trans
                          i18nKey="furtherTerms"
                          components={[
                            <a href="/terms" key={1}>
                              Click Here
                            </a>,
                            <a href="/terms" key={1}>
                              Click Here
                            </a>,
                          ]}
                        />
                        <Trans
                          i18nKey="furtherCondition"
                          components={[
                            <a href="/privacy" key={2}>
                              click here
                            </a>,
                            <a href="/privacy" key={2}>
                              Click Here
                            </a>,
                          ]}
                        />
                      </li>
                      <li> {t("byProceeding")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-center">{t("enterPIN")}</p>
              <OTPInput
                inputType="number"
                containerStyle={{
                  justifyContent: "center",
                  direction: "ltr",
                }}
                inputStyle={{
                  width: "40px",
                  height: "50px",
                  justifyContent: "space-between",
                  border: "2px solid black",
                  direction: "ltr",
                }}
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input required {...props} />}
                shouldAutoFocus={true}
              />

              <div className="submit-info mt-5">
                <button onClick={(e) => verify(e)} className="btn-submit w-100">
                  {lodaing ? (
                    t("subscribe") //اشترك
                  ) : (
                    <i className="fas fa-spinner fa-spin"></i>
                  )}
                </button>
              </div>
              <h6 style={{ direction: t("lg"), fontWeight: "bold" }}>
                {t("TERMS AND CONDITIONS")}
              </h6>
              <small>{t("By Clicking on Subscribe,condistion-ku")}</small>
              <ul
                dir={t("lg")}
                className=" mx-1 py-2"
                style={{
                  listStyle: "disc !important",
                  fontSize: "12px",
                  direction: t("lg"),
                  listStyleType: "disc",
                }}
              >
                <li> {t(" Enjoy your FREE TRIAL For 24 hours")}</li>
                <li> {t("No commitment")}</li>
                <li> {t("A standard subscription")}</li>
                <li> {t("A FREE TRIAL period")}</li>
                <li> {t("renewed")}</li>
                <li> {t("ageCondition")}</li>
                <li> {t("support")}</li>
                <li> {t("getSupport")}</li>

                <li>
                  {" "}
                  <Trans
                    i18nKey="furtherTerms"
                    components={[
                      <a href="/terms" key={1}>
                        Click Here
                      </a>,
                      <a href="/terms" key={1}>
                        Click Here
                      </a>,
                    ]}
                  />
                  <Trans
                    i18nKey="furtherCondition"
                    components={[
                      <a href="/privacy" key={2}>
                        click here
                      </a>,
                      <a href="/privacy" key={2}>
                        Click Here
                      </a>,
                    ]}
                  />
                </li>
                <li> {t("byProceeding")}</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EtisalatSignUp;
